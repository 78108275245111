import Vue from "vue";
import config from "./config";
import router from "./router";
import store from "./store";

// i18next for translations
import i18n from "./i18n";

import * as Sentry from "@sentry/vue";
Vue.config.devtools = true;
Sentry.init({
	Vue,
	dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
	integrations: [
		Sentry.browserTracingIntegration({
			router,
		}),
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.05,
	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/since\.love\/api/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.02,
	replaysOnErrorSampleRate: 1.0,
});
import "./bootstrap.js";
import "../../sass/users.scss";

// ImageKit
import ImageKit from "imagekitio-vue";
Vue.use(ImageKit, {
	urlEndpoint: "https://ik.imagekit.io/ctyttbqpls/",
	// publicKey: "your_public_api_key",
	// authenticationEndpoint: "https://www.your-server.com/auth"
});

// Vue Notifications
import Notifications from "vue-notification";
Vue.use(Notifications);

// Vue Modal
import VModal from "vue-js-modal";
Vue.use(VModal, {
	dialog: true,
});

// Vue Masonry
import VueMasonry from "vue-masonry-css";
Vue.use(VueMasonry);

import VCalendar from "v-calendar";
Vue.use(VCalendar);

// LoadScript
// import LoadScript from "vue-plugin-load-script";
// Vue.use(LoadScript);

// Vue Fontawesome
import "./fontawesome.js";

// Workbox PWA
import "./workbox.js";

// Set Global Vars
Vue.prototype.$publicUrl = config.publicUrl;
Vue.prototype.$appUrl = config.appUrl;
Vue.prototype.$appName = config.appName;
Vue.prototype.$appBaseUrl = config.appBaseUrl;
Vue.prototype.$defaultAvatar = config.avatar.default;
Vue.prototype.$hiddenAvatar = config.avatar.hidden;
Vue.prototype.$previewImage = config.previewImage;
Vue.prototype.$maxFileSize = config.maxFileSize;
Vue.prototype.$paypalClientId = config.paypalClientId;

// Get User Data First then load app
window.axios
	.get("/user")
	.then(async (response) => {
		document.getElementById("pageloader").classList.remove("is-active");
		store.dispatch("components/getCountriesOptions");
		store.dispatch("components/setInitialData", response.data);
		initializeVue();
		window.app.$connect();
	})
	.catch((error) => {
		if (error?.response && error.response.status === 401) {
			localStorage.removeItem("access_token");
			window.location.href = config.publicUrl;
		}
	});

// Initial Components
import baseapp from "./components/baseapp.vue";

import OneSignalVue from "onesignal-vue";
Vue.use(OneSignalVue);

// Websockets
import { initializeTheme } from "./helpers.js";

// Vue Init Function
function initializeVue() {
	window.app = new Vue({
		i18n,
		el: "#app",
		router,
		store,
		components: {
			baseapp,
		},
		computed: {
			init_finished: {
				get() {
					return this.$store.getters["components/getInitFinished"];
				},
				set(value) {
					this.$store.commit("components/setInitFinished", value);
				},
			},
			getUserData() {
				return this.$store.getters["components/getUserData"];
			},
		},
		created() {
			// Check if this account is deactivated
			if (!this.getUserData.active) {
				window.location.href = `${this.$publicUrl}deactivate/account`;
			}

			// Change App Language
			localStorage.setItem("language", this.getUserData.language);
			this.$i18n.locale = this.getUserData.language;

			// Set Videocall Room
			this.$store.commit(
				"videocall/setMyRoom",
				`videocall_${this.getUserData.id}`,
			);

			// Init Finished
			this.init_finished = true;
		},
	});
}
initializeTheme();
