import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import config from './config'

dayjs.extend(isSameOrAfter)

export function formatCurrency(value) {
    const val = parseFloat(value);
    return '$' + val.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function textTruncate(str, length = 100, ending = '...') {
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str
    }
}

export function setZoneScrollToBottom(id_element) {
    const container = document.querySelector(id_element);
    container.scrollTop = container.scrollHeight
}

export function setScrollToElement(id_element) {
    const element = document.querySelector(id_element);
    const y = element.getBoundingClientRect().top + window.scrollY
    window.scroll({ top: y, behavior: 'smooth' })
}

export function scrollToElementId(id_element) {
    const element = document.getElementById(id_element);
    element.scrollIntoView({ block: "center", behavior: "smooth" })
}

export function highlight(id_element, text, id_message) {
    const inputText = document.getElementById(id_element);
    let innerHTML = inputText.innerHTML;
    const index = innerHTML.indexOf(text);
    if (index >= 0) {
        innerHTML = innerHTML.substring(0, index) + `<span id="highlight-${id_message}" class="highlight">` + innerHTML.substring(index, index + text.length) + "</span>" + innerHTML.substring(index + text.length);
        inputText.innerHTML = innerHTML;
    }
}

export function unhighlight(id_element) {
    const element = document.getElementById(id_element);
    if (element == null) return
    if (element.classList.contains('highlight')) {
        element.classList.remove('highlight')
    }
}

export function addClassHighlight(id_element) {
    const element = document.getElementById(id_element);
    element.classList.add('highlight')
}

export function timestampToString(timestamp, format) {
    if (timestamp === null) {
        return ''
    }
    dayjs.locale(localStorage.getItem('language'))
    return dayjs(timestamp).format(format);
}

export function checkIfUserOnline(active) {
    if (active === 1) {
        return 'online'
    } else {
        return 'disconnected'
    }
}

export function getReadableDate(timestamp) {
    if (timestamp == null) {
        return ''
    }
    dayjs.locale(localStorage.getItem('language'))
    const now = dayjs();
    const last_online_at = dayjs(timestamp);
    const hours = now.diff(last_online_at, 'hours');
    if (hours === 0) {
        const timeDiff = now.diff(last_online_at, 'minutes')
        if (timeDiff <= 0) return 'Last seen recently'
        return 'Last online ' + timeDiff + ' minutes ago'
    }
    if (hours > 24) {
        const timeDiff = now.diff(last_online_at, 'days')
        return 'Last online ' + timeDiff + ' days ago'
    }
    return 'Last online ' + hours + ' hour(s) ago'
}

export function isValidFileSize(file, maxsize) {
    if (file.size > maxsize) {
        return false
    }
    {
        return true
    }
}

export function isValidImageExtension(file) {
    return /\.(jpg|jpeg|png|gif)$/i.test(file.name);
}

export function isStorageFile(path) {
    if (!/^(f|ht)tps?:\/\//i.test(path)) {
        return config.cloudFrontUrl + '/' + path
    } else {
        return path
    }
}

export function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size)
}

export function isDateSameOrAfterNow(date) {
    return dayjs(date).isSameOrAfter(dayjs())
}

export function applyTheme(theme) {
    const html = document.documentElement;

    html.classList.remove("theme-dark", "theme-light");

    if (theme === "dark") {
        html.classList.add("theme-dark");
        // Override css variables
    } else if (theme === "light") {
        html.classList.add("theme-light");
    }
}

export function setTheme(theme) {
    localStorage.setItem("theme", theme);
    applyTheme(theme);
}

export function initializeTheme() {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
        applyTheme(savedTheme);
    } else {
        const prefersDarkMode = window.matchMedia(
            "(prefers-color-scheme: dark)"
        ).matches;
        applyTheme(prefersDarkMode ? "dark" : "light");
    }
}

export function getTheme() {
    return localStorage.getItem("theme");
}
