if ("serviceWorker" in navigator) {
	navigator.serviceWorker.getRegistrations().then((registrations) => {
		for (const registration of registrations) {
			registration.unregister().then(() => {
				console.log("Service worker unregistered", registration);
			});
		}
	});
}
if ("caches" in window) {
	caches.keys().then((names) => {
		for (const name of names) {
			caches.delete(name).then((r) => {
				console.log("Cache deleted", name);
			});
		}
	});
}
