import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

const appUrl = document.head
	.querySelector('meta[name="app_url"]')
	.getAttribute("content");

// Modules Routes
let routes = [];
import components from "./components/routes";
import chat from "./modules/chat/routes";
import credits from "./modules/credits/routes";
import gifts from "./modules/gifts/routes";
import legal from "./modules/legal/routes";
import meeting from "./modules/meeting/routes";
import memberships from "./modules/memberships/routes";
import posts from "./modules/posts/routes";
import profile from "./modules/profile/routes";
import profiles from "./modules/profiles/routes";
import settings from "./modules/settings/routes";

// Juntar las Rutas
routes = routes.concat(
	components,
	posts,
	chat,
	meeting,
	credits,
	memberships,
	gifts,
	profile,
	profiles,
	settings,
	legal,
);
Vue.use(VueRouter);

const router = new VueRouter({
	//mode: 'history', //removes # (hashtag) from url
	base: appUrl,
	fallback: true, //router should fallback to hash (#) mode when the browser does not support history.pushState
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

// Check Active Membership
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresActiveMembership)) {
		if (store.getters["components/getUserDataMembershipExpired"]) {
			next(false);
		} else {
			next();
		}
	} else {
		next();
	}
});

// Check Approved User
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requireBeApproved)) {
		if (!store.getters["components/getUserApproved"]) {
			next({ name: "My Profile" });
		} else {
			next();
		}
	} else {
		next();
	}
});

// Is Timeout User
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.notTimeOut)) {
		if (store.getters["components/getTimeOut"]) {
			next({ name: "My Profile" });
		} else {
			next();
		}
	} else {
		next();
	}
});

// Is Suspended User
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.notSuspendedUser)) {
		if (store.getters["components/getSuspended"]) {
			next({ name: "My Profile" });
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
